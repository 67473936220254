.nav {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;
	&__ul {
		margin: 0;
		padding: 0;
		display: flex;
		list-style-type: none;
		align-items: center;
		justify-content: center;
	}
	&__li {
		width: 100%;
	}
	&__a {
		width: 100%;
		display: block;
		background-color: burlywood;
	}
}
