@keyframes rotateArrow {
	100% {
		transform: rotate(360deg);
	}
}
.loading-wrapper {
	position: relative;
	width: 100%;
	// height: 100vh;
	.loading-inner-wrapper {
		position: absolute;
		z-index: 100;
		top: 50vh;
		left: 50%;
		transform: translate(-50%, -50%);
		// background-color: aliceblue;
		border-radius: 50%;
		padding: 3px;

		.loading-arrow-bootstrap-logo {
			animation: rotateArrow ease-in-out 0.7s infinite;
			margin: 0 auto;
			display: block;
			width: 50px;
			height: auto;
		}
	}
}
